import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class InmueblesService {

  constructor(private httpClient: HttpClient) { }

  inmuebles(operacion, tiposinmueble, dormitorios, page, per_page){
    tiposinmueble = tiposinmueble === "" ? "todos" : tiposinmueble;
    dormitorios   = dormitorios === "" ? "todos" : dormitorios;

    return this.httpClient
      .get(`${environment.apiUrl}/inmuebles/${operacion}/${tiposinmueble}/${dormitorios}?page=${page}&per_page=${per_page}`);
  }

  inmueble(idinmueble){
    return this.httpClient.get(`${environment.apiUrl}/inmuebles/${idinmueble}`);
  }

}

import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {SearchComponent} from './components/search/search.component';
import {InmuebleComponent} from './components/inmueble/inmueble.component';
import {ContactComponent} from './components/contact/contact.component';

const APP_ROUTES: Routes = [
  { path: '', component: HomeComponent},
  // { path: '/', component: HomeComponent, pathMatch: 'full' },
  // {path: 'administrador'},
  {path: 'busqueda-de-inmuebles/:operacion',
   // component: HomeComponent,
   children: [
      { path: 'inmuebles/:tiposinmueble/dormitorios/:dormitorios',  component: SearchComponent, pathMatch: 'full' },
      { path: 'dormitorios/:dormitorios',  component: SearchComponent, pathMatch: 'full' },
      { path: 'inmuebles/:tiposinmueble',  component: SearchComponent, pathMatch: 'full' },
      { path: '', component: SearchComponent, pathMatch: 'full' },
      { path: '**', redirectTo: '/' }
    ]
  },
  { path: 'inmuebles/:idinmueble',    component: InmuebleComponent, pathMatch: 'full' },
  { path: 'contacto',    component: ContactComponent, pathMatch: 'full' },
  // {path: 'busqueda-de-inmuebles', component: HomeComponent},
  {path: '**', pathMatch: 'full', redirectTo: '/'}
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES,

  {
    // Tell the router to use the hash instead of HTML5 pushstate.
    useHash: true,

    // In order to get anchor / fragment scrolling to work at all, we need to
    // enable it on the router.
    anchorScrolling: "enabled",

    // Once the above is enabled, the fragment link will only work on the
    // first click. This is because, by default, the Router ignores requests
    // to navigate to the SAME URL that is currently rendered. Unfortunately,
    // the fragment scrolling is powered by Navigation Events. As such, we
    // have to tell the Router to re-trigger the Navigation Events even if we
    // are navigating to the same URL.
    // onSameUrlNavigation: "reload",

    // Let's enable tracing so that we can see the aforementioned Navigation
    // Events when the fragment is clicked.
    enableTracing: false,
    scrollPositionRestoration: "enabled"
  });



import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-sidenav',
  templateUrl: './content-sidenav.component.html',
  styleUrls: ['./content-sidenav.component.css']
})
export class ContentSidenavComponent implements OnInit {

  image = 'assets/daniel-chen-476672-unsplash.jpg';

  dormitorios = "";
  operacion = "";
  tiposinmueble = "";

  constructor() { }

  ngOnInit() {
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-carousel-navigation',
  templateUrl: './carousel-navigation.component.html',
  styleUrls: ['./carousel-navigation.component.css'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})


export class CarouselNavigationComponent implements OnInit {

  // showNavigationArrows = false;
  // showNavigationIndicators = false;
  // `https://picsum.photos/900/500?random&t=${Math.random()}`
  // images = [1, 2, 3].map(() => 'assets/rawpixel-1053187-unsplash.jpg');
  // images = ['assets/rawpixel-1053187-unsplash.jpg', 'assets/alvin-engler-48444-unsplash.jpg',
  //   'assets/dane-deaner-272375-unsplash.jpg', 'assets/daniel-chen-476672-unsplash.jpg',
  //   'assets/gus-ruballo-128864-unsplash.jpg', 'assets/michael-mroczek-124413-unsplash.jpg'];

  @Input() images: string[];
  @Input() id: number;
  @Input() width = "100%";
  imagepath = environment.imagepath;
  // images = ['assets/daniel-chen-unsplash.jpg'];

  constructor(config: NgbCarouselConfig, private _http: HttpClient) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnInit {
  checked = false;
  indeterminate = false;
  operacion = 'alquiler-o-venta';

  // tipoInmueble = new FormControl();

  // operacion = new FormControl();
  dormitorio = []; // new FormControl();

  operacionList = [
    {name: 'Cualquier operación', slug: 'alquiler-o-venta'},
    {name: 'Alquiler', slug: 'alquiler'},
    {name: 'Venta', slug: 'venta'},
  ];

  tipoInmuebleList  = [
    {name: 'Apartamento', slug: 'apartamento'},
    {name: 'Casa', slug: 'casa'},
    {name: 'Chacra/Campo', slug: 'chacra_campo'},
    {name: 'Complejo', slug: 'complejo'},
    {name: 'Garage', slug: 'garage'},
    {name: 'Local comercial', slug: 'local_comercial'},
    {name: 'Otros', slug: 'otros'},
    {name: 'Posada', slug: 'posada'},
    {name: 'Terreno', slug: 'terreno'},
  ];

  tipoInmueble = [];
  dormitorioList =
    [
      {name: 'Monoambiente', slug: 'monoambiente'},
      {name: '1', slug: '1'},
      {name: '2', slug: '2'},
      {name: '3 ó más', slug: '3_o_mas'}
      // {name: 'Otros', slug: 'Otros'},
    ];

  constructor(private router: Router) { }

  ngOnInit() {
  }
  // private activatedRoute : ActivatedRoute
//   this.activatedRoute.params.subscribe(values => {
//   console.log(values);//Which will print the properties you have passed
// });

  search(){

    const existe_ruta_tipoInmueble =  this.tipoInmueble.filter(item => this.tipoInmuebleList.find(test => test.slug === item));

    if (existe_ruta_tipoInmueble.length !== this.tipoInmueble.length){
      this.router.navigate(['/']);
      return;
    }

    const existe_ruta_dormitorio =  this.dormitorio.filter(item => this.dormitorioList.find(test => test.slug === item));

    if (existe_ruta_dormitorio.length !== this.dormitorio.length){
      this.router.navigate(['/']);
      return;
    }

    let url_search = "/" + this.operacion;
    if (this.tipoInmueble.length > 0 ) {
      url_search += "/inmuebles/" + this.tipoInmueble.join('-');
    }


    if (this.dormitorio.length > 0 ) {
      url_search += "/dormitorios/" + this.dormitorio.join('-');
    }

    // if (this.operacion === "alquiler-o-venta") {
    //   this.router.navigate(['/busqueda-de-inmuebles']);
    // }else {
    this.router.navigateByUrl('busqueda-de-inmuebles' + url_search);
    // this.router.navigate(['/busqueda-de-inmuebles', this.operacion]);
  }

}

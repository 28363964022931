import {Component, Inject, OnInit} from '@angular/core';
import {Inmueble} from '../../interfaces/inmueble';
import {HelperService} from '../../services/helper.service';
import {InmueblesService} from '../../services/inmuebles.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FilesPipe} from '../../pipes/files.pipe';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';


@Component({
  selector: 'app-inmueble',
  templateUrl: './inmueble.component.html',
  styleUrls: ['./inmueble.component.css'],
  // providers: [localStorageService],

})
export class InmuebleComponent implements OnInit {

  images = []; // ['assets/daniel-chen-unsplash.jpg'];
  width = '100%';
  caracteristicas = [
    {text: "Año de Construcción", value: 1939},
    {text: "Baños", value: 4},
    {text: "Cocina", value: "Si"},
    {text: "Dormitorios", value: 3},
    {text: "Estado", value: "Muy Bueno"},
    {text: "Estufa a Leña", value: "Si"},
    {text: "Garage", value: "Si"},
    {text: "Jardín", value: "Si"},
    {text: "Living Comedor", value: "Si"},
    {text: "Parrillero", value: "Si"},
    {text: "Piscina", value: "Si"},
    {text: "Plantas", value: 2},
    {text: "Superficie Cubierta", value: 306},
    {text: "Superficie Total", value: 960},
    {text: "Terrazas", value: "Si"},
    ];

  inmueble: any = {caracteristicas: []}; // init in empty to avoid console error
  idinmueble: any;
  contactFormlabel = "Consultar sobre este inmueble";

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private inmueblesService: InmueblesService,
              private helperService: HelperService,
              private filesPipe: FilesPipe,
              @Inject(LOCAL_STORAGE) private localStorage: StorageService
              ) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.activatedRoute.params.subscribe(params => {

      this.idinmueble = params["idinmueble"];
      this.inmueblesService.inmueble(this.idinmueble)
        .subscribe(inmueble => {
          this.localStorage.set('navigatedElement', this.idinmueble);
          this.inmueble = inmueble;
          this.inmueble.operacion = this.helperService.getNombreOperacion(this.inmueble.operacion);
          this.inmueble.nombrepropiedad = this.helperService.getNombrePropiedad(this.inmueble.tipopropiedad),
          this.inmueble.files = this.filesPipe.transform(this.inmueble.files);
        });
    });
  }

  currentRoute(){
    return this.router.url;
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../interfaces/contact';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {ContactService} from '../../services/contact.service';
import {HelperService} from '../../services/helper.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {

  @Input() formLabel = 'Envíenos su consulta';
  @Input() _reference = null;

  errorMessages;
  _contact = new Contact();

  constructor(private recaptchaV3Service: ReCaptchaV3Service,
              private contactService: ContactService,
              private helperService: HelperService,
              private router: Router) { }

  ngOnInit() {
  }

  /**
   * Process the contact form on submit event.
   */
  public contact(contactData: any): void {

    this.helperService.setLoader(true);
    this.recaptchaV3Service.execute('contact')
      .subscribe((token) => {
          // console.log(token);
          if (contactData) {

            const postData = {
              name: contactData.name,
              email: contactData.email,
              subject: contactData.subject,
              message: contactData.message,
              token: token,
              reference: this._reference
            };

            // post both the form-data and captcha data to the backend
            this.contactService.send(postData)
              .subscribe(
                response => {
                  this.helperService.setLoader(false);
                  if (response.success === false) {
                    // captcha validation failed; show the error message
                    this.errorMessages = response.errors;
                    // in order to generate a new captcha challenge
                  } else {
                    console.log('message sent');
                    // captcha validation succeeded; proceed with the workflow
                    this.router.navigate(['/']);
                  }
                },
                error => {
                  if (error.error){
                    this.errorMessages = error.error.error.subject;
                  }
                  this.helperService.setLoader(false);
                });

          } else {
            this.helperService.setLoader(false);
            console.log(contactData);
            this.errorMessages = {formInvalid: 'Please enter valid values.'};
          }
        },
        error => {
          this.helperService.setLoader(false);
        });
  }

  isLoading() {
    return this.helperService.isLoading();
  }
}

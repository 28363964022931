import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  dormitorios = "";
  operacion = "";
  tiposinmueble = "";

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.dormitorios = params["dormitorios"];
      this.operacion = params["operacion"];
      this.tiposinmueble = params["tiposinmueble"];
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'files'
})
export class FilesPipe implements PipeTransform {

  transform(files: any, args?: any): any {
    return files.map(file => file.name);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CarouselNavigationComponent } from './components/carousel-navigation/carousel-navigation.component';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatGridListModule, MatIconModule, MatInputModule, MatProgressSpinnerModule, MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbar,
  MatToolbarModule
} from '@angular/material';
import { ContentSidenavComponent } from './components/content-sidenav/content-sidenav.component';
import { HeaderComponent } from './components/header/header.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import {APP_ROUTING} from './app.routes';
import { HouseListComponent } from './components/house-list/house-list.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { SearchComponent } from './components/search/search.component';
import { BuscadorFlatComponent } from './components/buscador-flat/buscador-flat.component';
import { InmuebleComponent } from './components/inmueble/inmueble.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import localeUy from '@angular/common/locales/es-UY';
import {registerLocaleData} from '@angular/common';
import { FilesPipe } from './pipes/files.pipe';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxSpinnerModule} from 'ngx-spinner';

registerLocaleData(localeUy, 'uy');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CarouselNavigationComponent,
    ContentSidenavComponent,
    HeaderComponent,
    BuscadorComponent,
    HouseListComponent,
    SearchComponent,
    BuscadorFlatComponent,
    InmuebleComponent,
    FooterComponent,
    ContactComponent,
    FilesPipe,
    ContactFormComponent,

  ],
  imports: [
    BrowserModule, NgbModule, HttpClientModule, BrowserAnimationsModule,
    MatCardModule, MatButtonModule, MatToolbarModule, MatGridListModule, MatSidenavModule, MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    APP_ROUTING,
    FlexLayoutModule,
    FormsModule,
    RecaptchaV3Module,
    InfiniteScrollModule,
    NgxSpinnerModule,
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'uy' }, FilesPipe,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeAt7IUAAAAAI_M-p9RYv8JYVVyUIpFALGdGGH-' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

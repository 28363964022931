import {AfterViewChecked, Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {InmueblesService} from '../../services/inmuebles.service';
import {Inmueble} from '../../interfaces/inmueble';
import {HelperService} from '../../services/helper.service';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {I18nPluralPipe, ViewportScroller} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';


@Component({
  selector: 'app-house-list',
  templateUrl: './house-list.component.html',
  styleUrls: ['./house-list.component.css'],
})
export class HouseListComponent implements OnInit, OnChanges {

  @Input() dormitoriosin;
  @Input() operacionin;
  @Input() tiposinmueblein;

  dormitorios = '';
  operacion = 'alquiler-o-venta';
  tiposinmueble = '';
  page = 1;
  per_page = 12;
  has_more = true;
  selectedInmueble: string = null;

  constructor(private inmueblesService: InmueblesService,
              private helperService: HelperService,
              private activatedRoute: ActivatedRoute,
              @Inject(LOCAL_STORAGE) private localStorage: StorageService,
              private readonly viewportScroller: ViewportScroller
              ) {
  }

  _inmuebles: any[] = [];
  inmuebles!: Observable<any[]>;
  imagepath = environment.imagepath;

  itemPluralMapping = {
    'banios': {
      '=0': '0 Baños',
      '=1': '1 Baño',
      'other': '# Baños'
    },
    'dormitorios': {
      '=0': 'Monoambiente',
      '=1': '1 Dormitorio',
      'other': '# Dormitorios'
    },
    'hectareas': {
      '=0': 'hectáreas',
      '=1': '1 hectáreas',
      'other': '# hectáreas'
    },
  };

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    this.page = 1;
    this.has_more = true;
    this.operacion = changes.operacionin !== undefined ? changes.operacionin.currentValue : this.operacion;
    this.tiposinmueble = changes.tiposinmueblein !== undefined ? changes.tiposinmueblein.currentValue : this.tiposinmueble;
    this.tiposinmueble = this.tiposinmueble !== undefined ? this.tiposinmueble : '';
    this.dormitorios = changes.dormitoriosin !== undefined ? changes.dormitoriosin.currentValue : this.dormitorios;
    this.dormitorios = this.dormitorios !== undefined ? this.dormitorios : '';

    this.helperService.setLoader(true);
    this.inmueblesService.inmuebles(this.operacion, this.tiposinmueble, this.dormitorios, this.page, this.per_page)
      .pipe(
        map((result: any) => {
          return result.data;
        }),
        map((data: Inmueble[]) => data.map(inmuebleData => {
            return {
              id: inmuebleData.id,
              slug: inmuebleData.slug,
              // operacion: inmuebleData.operacion,
              operacion: this.helperService.getNombreOperacion(inmuebleData.operacion),
              nombrepropiedad: this.helperService.getNombrePropiedad(inmuebleData.tipopropiedad),
              tipopropiedad: inmuebleData.tipopropiedad,
              dormitorios: inmuebleData.dormitorios,
              banios: inmuebleData.banios,
              superficie: inmuebleData.superficie,
              direccion: inmuebleData.direccion,
              precio: inmuebleData.precio,
              moneda: inmuebleData.moneda,
              descripcion: inmuebleData.descripcion,
              caracteristicas: inmuebleData.caracteristicas,
              imagepath: inmuebleData.imagepath,
              files: inmuebleData.files,
              available: inmuebleData.available,
            };
          })
        ))
      .subscribe((data: Inmueble[]) => {
        this._inmuebles = this._inmuebles.concat(data);

        this._inmuebles.sort((a, b) => {
          let aPrice = a.precio;
          let bPrice = b.precio;
          if (a.moneda !== b.moneda){
            aPrice = a.moneda === 'USD' ? a.precio * 44 : a.precio;
            bPrice = b.moneda === 'USD' ? b.precio * 44 : b.precio;
          }
          return parseInt(aPrice, 10) - parseInt(bPrice, 10);
        });
        console.log('changes', this._inmuebles);

        this.inmuebles = of(this._inmuebles);
        this.selectedInmueble = this.localStorage.get('navigatedElement');
        const foundInmueble = this._inmuebles.find(op =>  this.selectedInmueble === op.slug);
        if (foundInmueble !== undefined){
          this.viewportScroller.scrollToAnchor(this.selectedInmueble);
        }
        this.helperService.setLoader(false);
      });
  }

  isLoading() {
    return this.helperService.isLoading();
  }

  onScroll() {

    if (this.has_more) {
      this.page++;
      // this.helperService.setLoader(true);
      this.inmueblesService.inmuebles(this.operacion, this.tiposinmueble, this.dormitorios, this.page, this.per_page)
        .pipe(map((result: any) => {
            this.has_more = result.has_more;
            return result.data;
          }),
          map((data: Inmueble[]) => data.map(inmuebleData => {
              return {
                id: inmuebleData.id,
                slug: inmuebleData.slug,
                operacion: this.helperService.getNombreOperacion(inmuebleData.operacion),
                nombrepropiedad: this.helperService.getNombrePropiedad(inmuebleData.tipopropiedad),
                tipopropiedad: inmuebleData.tipopropiedad,
                dormitorios: inmuebleData.dormitorios,
                banios: inmuebleData.banios,
                superficie: inmuebleData.superficie,
                direccion: inmuebleData.direccion,
                precio: inmuebleData.precio,
                moneda: inmuebleData.moneda,
                descripcion: inmuebleData.descripcion,
                caracteristicas: inmuebleData.caracteristicas,
                imagepath: inmuebleData.imagepath,
                files: inmuebleData.files,
                available: inmuebleData.available,
              };
            })
          ))
        .subscribe((data: Inmueble[]) => {
          this._inmuebles = this._inmuebles.concat(data);
          this._inmuebles.sort((a, b) => {
            let aPrice = a.precio;
            let bPrice = b.precio;
            if (a.moneda !== b.moneda){
              aPrice = a.moneda === 'USD' ? a.precio * 44 : a.precio;
              bPrice = b.moneda === 'USD' ? b.precio * 44 : b.precio;
            }
            return parseInt(aPrice, 10) - parseInt(bPrice, 10);
          });
          console.log('scroll', this._inmuebles);

          this.inmuebles = of(this._inmuebles);
          this.selectedInmueble = this.localStorage.get('navigatedElement');
          const foundInmueble = this._inmuebles.find(op =>  this.selectedInmueble === op.slug);
          if (foundInmueble !== undefined){
            this.viewportScroller.scrollToAnchor(this.selectedInmueble);
          }
          this.helperService.setLoader(false);
        });
    }
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-buscador-flat',
  templateUrl: './buscador-flat.component.html',
  styleUrls: ['./buscador-flat.component.css']
})
export class BuscadorFlatComponent implements OnInit {

  checked = false;
  indeterminate = false;
  _operacion = 'alquiler-o-venta';

  @Input() dormitoriosin;
  @Input() operacionin;
  @Input() tiposinmueblein;

  _dormitorios = []; // new FormControl();

  operacionList = [
    {name: 'Cualquier operación', slug: 'alquiler-o-venta'},
    {name: 'Alquiler', slug: 'alquiler'},
    {name: 'Venta', slug: 'venta'},
  ];

  tipoInmuebleList  = [
    {name: 'Apartamento', slug: 'apartamento'},
    {name: 'Casa', slug: 'casa'},
    {name: 'Chacra/Campo', slug: 'chacra_campo'},
    {name: 'Complejo', slug: 'complejo'},
    {name: 'Garage', slug: 'garage'},
    {name: 'Local comercial', slug: 'local_comercial'},
    {name: 'Otros', slug: 'otros'},
    {name: 'Posada', slug: 'posada'},
    {name: 'Terreno', slug: 'terreno'},
  ];

  _tipoInmueble = [];
  dormitorioList =
    [
      {name: 'Monoambiente', slug: 'monoambiente'},
      {name: '1', slug: '1'},
      {name: '2', slug: '2'},
      {name: '3 ó más', slug: '3_o_mas'}
      // {name: 'Otros', slug: 'Otros'},
    ];


  operacionCtrl: FormControl;
  tipoInmuebleCtrl: FormControl;
  dormitoriosCtrl: FormControl;

  constructor(private router: Router) { }

  ngOnInit() {
    /**
     *  Inicializa formulario de busqueda de acuerdo a los parametros ingresados
     */

    if (this.dormitoriosin !== undefined) {
      this._dormitorios = this.filtrarOpcionesSeleccionadas(this.dormitorioList, this.dormitoriosin.split("-"));
    }

    if (this.operacionin !== undefined) {
      this._operacion =  this.operacionList.find(op =>  this.operacionin === op.slug).slug;
    }

    if (this.tiposinmueblein !== undefined) {
      this._tipoInmueble = this.filtrarOpcionesSeleccionadas(this.tipoInmuebleList, this.tiposinmueblein.split("-"));
    }

    this.operacionCtrl =  new FormControl(this._operacion);
    this.tipoInmuebleCtrl = new FormControl(this._tipoInmueble);
    this.dormitoriosCtrl =  new FormControl(this._dormitorios);

    /**
     * Se suscribe a los cambios que el usuario realice en el buscador, para luego en el search() acceder a las modificaciones
     */
    this.operacionCtrl.valueChanges
      .subscribe(term => {
        this._operacion = term;
    });

    this.tipoInmuebleCtrl.valueChanges
      .subscribe(term => {
        this._tipoInmueble = term;
    });

    this.dormitoriosCtrl.valueChanges
      .subscribe(term => {
        this._dormitorios = term;
    });
  }


  search(){

    let url_search = "/" + this._operacion;

    if (this._tipoInmueble.length > 0 ) {
      url_search += "/inmuebles/" + this._tipoInmueble.join('-');
    }

    if (this._dormitorios.length > 0 ) {
      url_search += "/dormitorios/" + this._dormitorios.join('-');
    }

    this.router.navigate(['/busqueda-de-inmuebles' + url_search]);
  }

  filtrarOpcionesSeleccionadas(opcionesPosibles, opcionesSeleccionadas){

    return  opcionesPosibles.reduce(function(filtered, option) {
      if (opcionesSeleccionadas.includes(option.slug)) {
        filtered.push(option.slug);
      }
      return filtered;
    }, []);
  }

}

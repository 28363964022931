import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  loading = false;

  propiedadesById = {
    0: 'Apartamento',
    1: 'Casa',
    2: 'Chacra/Campo',
    3: 'Garage',
    4: 'Local comercial',
    5: 'Otros',
    6: 'Terreno',
    7: 'Complejo',
    8: 'Posada',
  };

  operacionById = {
    0: 'Alquiler/Venta',
    1: 'Alquiler',
    2: 'Venta',
  };

  constructor() { }

  isLoading() {return this.loading === true; }

  setLoader(loading) {this.loading = loading; }

  getNombrePropiedad(tipopropiedad: number): string{
    return this.propiedadesById[tipopropiedad];
  }

  getNombreOperacion(idOperacion: number): string{
    return this.operacionById[idOperacion];
  }

}
